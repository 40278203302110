/* Weirdly, Chrome recognizes this but not the inline style. Firefox recognizes the inline style but not this file. Weirdddd */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: white;
}
::-webkit-scrollbar-thumb {
  background: grey;
}
